import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Docker from '../assets/images/customers/docker.svg'
import Factorial from '../assets/images/customers/factorial.svg'
import Matillion from '../assets/images/customers/matillion.svg'
import Miro from '../assets/images/customers/miro.svg'
import Pleo from '../assets/images/customers/pleo.svg'
import Truelayer from '../assets/images/customers/truelayer.svg'
import Webflow from '../assets/images/customers/webflow.svg'
import WeTransfer from '../assets/images/customers/wetransfer.svg'
import Block from './Block'

const CustomerLogos = () => {
  return (
    <CustomersContainer>
      <Block type="padding-small" size="large" centered>
        <h5>Trusted by effective engineering&nbsp;organizations</h5>
        <ul className="logos-container">
          <li>
            <img src={Miro} alt="Miro" />
            <Link to="/story/miro/">Read story</Link>
          </li>
          <li>
            <img src={Webflow} alt="Webflow" />
          </li>
          <li>
            <img src={Matillion} alt="Matillion" />
          </li>
          <li>
            <img src={WeTransfer} alt="WeTransfer" />
          </li>
          <li>
            <img src={Truelayer} alt="Truelayer" />
            <Link to="/story/truelayer/">Read story</Link>
          </li>
          <li>
            <img src={Pleo} alt="Pleo" />
            <Link to="/story/pleo/">Read story</Link>
          </li>
          <li>
            <img src={Docker} alt="Docker" />
          </li>

          <li>
            <img src={Factorial} alt="Factorial" />
            <Link to="/story/factorial/">Read story</Link>
          </li>
        </ul>
      </Block>
    </CustomersContainer>
  )
}

export default CustomerLogos

const CustomersContainer = styled.div`
  p {
    font-weight: 700;
  }

  .logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 12px;
    column-gap: 16px;
    margin-top: 16px;

    & > li {
      width: calc(50% - 16px);
      height: 64px;
      max-width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 16px;
      margin: 0;

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 12px;
        right: 12px;
        letter-spacing: 1px;
        font-size: 0.7rem;
        text-transform: uppercase;
        color: #1a66d8;
        opacity: 0;
        font-weight: 700;
        padding-top: 48px;

        &:hover {
          opacity: 1;
        }
      }

      img {
        max-width: 100%; /* max dimensions on the images so they always fit */
        max-height: 40px;
        height: auto; /* maintain aspect ratio */
        width: auto;
      }
    }
  }
`
